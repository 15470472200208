<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="image"
      height="270"
    />
    <apexchart
      v-else
      type="bar"
      height="260"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    loading: {
      type: Boolean,
      defualt() {
        return false;
      },
    },
    title: {
      type: String,
      default() {
        return "User Growth";
      },
    },
    item: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    series() {
      return [
        {
          name: this.title,
          data: this.item.map((r) => ({
            x: `${r.start_date} -- ${r.end_date}`,
            y: r.customer_count,
          })),
        },
      ];
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          labels: {
            rotate: -45, // Rotates labels for better visibility
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              colors: "#333", // Custom label color
            },
            formatter: function (val) {
              if (!val.trim()) return "";

              // Trim the value
              let str = val.trim();

              // Split the start and end dates
              let dates = str.split("--").map((date) => date.trim());
              let fDates = dates.map((r) => moment(r).format("DD-MMM"));
              return `${fDates.join(" / ")}`; // Custom formatting
            },
          },
          axisBorder: {
            show: true, // Show bottom axis border
            color: "#ccc",
          },
          axisTicks: {
            show: true, // Show ticks under labels
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return val + " Customers"; // Custom tooltip format
            },
          },
        },
      };
    },
  },
};
</script>
